import type { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';

const ServicesApp = ({ Component, pageProps }: AppProps) => (
  <>
    <Head>
      <title>Aidium - Services</title>
    </Head>
    <main>
      <Component {...pageProps} />
    </main>
  </>
);

export default ServicesApp;
